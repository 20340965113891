import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store'
import router from './router'
import axios from 'axios';

import { useStore } from "vuex";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUpRightFromSquare, faThumbsDown, faMobileScreen, faPeopleArrowsLeftRight, faImage, faPencil, faBars, faSpinner, faCaretDown, faPen, faDownload, faSort, faCheck, faUserTie, faArrowLeft, faAngleLeft, faAngleRight, faPlus, faCircleCheck, faCircleXmark, faBuildingUser, faTrashCan, faXmark, faEye, faEyeSlash, faFloppyDisk, faMobileScreenButton, faFilter, faEraser, faBuildingCircleExclamation, faLocationDot, faFileInvoiceDollar, faEnvelope, faCalendarDay, faCashRegister, faInbox, faChartLine, faPowerOff, faCopy, faStar, faHouseChimneyWindow, faPlay, faPause, faMagnifyingGlass, faTriangleExclamation, faTrash, faCoins, faEarthAmerica, faEarthAmericas, faMinus, faMemory, faThumbsUp, faBug, faIdCard, faBuilding, faUser, faShare, faWindowRestore, faTicket, faChartColumn, faLocationPin, faBan, faFolderClosed, faHome, faClockRotateLeft, faCheckToSlot, faFileCsv, faRightToBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CKEditor from '@ckeditor/ckeditor5-vue'
import vSelect from 'vue-select'
import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import GoogleSignInPlugin from "vue3-google-signin"
import vue3GoogleLogin from 'vue3-google-login'
import VPagination from "@hennge/vue3-pagination";
import Echo from "laravel-echo";

import './input.css'
import './base.css'
import 'vue-select/dist/vue-select.css';
import 'v-calendar/dist/style.css';
import 'tw-elements';
import 'sweetalert2';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

store.dispatch('__init');
/*window.Pusher = require("pusher-js");
window.Echo = new Echo({
    disableStats: true,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    encrypted: false,
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKETS_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
    cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
    wsPort: process.env.VUE_APP_WEBSOCKETS_PORT,
    wssPort: process.env.VUE_APP_WEBSOCKETS_PORT,
})*/

library.add(faThumbsDown, faFolderClosed, faBan, faLocationPin, faChartColumn, faTicket, faWindowRestore, faShare, faBuilding, faUser, faMobileScreen, faIdCard, faBug, faMagnifyingGlass, faThumbsUp, faMemory, faPeopleArrowsLeftRight, faMinus, faEarthAmericas, faCoins, faTrash, faTriangleExclamation, faMagnifyingGlass, faSpinner, faPlay, faPause, faHouseChimneyWindow, faStar, faCopy, faTrashCan, faPowerOff, faChartLine, faInbox, faCashRegister, faCalendarDay, faEnvelope, faFileInvoiceDollar, faLocationDot,faBuildingCircleExclamation, faImage, faPencil, faBars, faSpinner, faCaretDown, faPen, faDownload, faSort, faCheck, faUserTie, faArrowLeft, faAngleLeft, faAngleRight, faPlus, faCircleCheck, faCircleXmark, faBuildingUser, faTrashCan, faXmark, faEye, faEyeSlash, faFloppyDisk, faMobileScreenButton, faFilter, faEraser, faUpRightFromSquare, faWhatsapp, faHome, faClockRotateLeft, faCheckToSlot, faFileCsv, faRightToBracket)
axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
}
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.get = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
}
axios.defaults.headers.delete = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
}
axios.interceptors.request.use((config) => {
    return {
      ...config,
      headers: {
        Authorization: localStorage.getItem('__token') ? `Bearer ${localStorage.getItem('__token')}` : ''
      }
    }
});

const app = createApp(App)
    .use(store)
    .use(router);
app.config.globalProperties.$store = store;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$dato = {
    print(dato) {
        if (
            dato === null ||
            dato === ''
        ) {
            return 'SIN DATO'
        }
        return dato;
    }
}
app.config.globalProperties.$formats = {

    currency(value) {
        return new Intl.NumberFormat("es-AR", { style: 'currency', currency: 'ARS', minimumFractionDigits: 0 }).format(value);
    },
    percentage(value) {
        return Number(value/100).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:2});
    },
    date(data, options = {}) {
        return new Date(data).toLocaleDateString('en-CA', options);
    },
    dia(data, options = {}) {
        return new Date(data).toLocaleDateString(undefined, options);
    },
    address(data)
    {
        let address = data.direccion ? data.direccion :  '';
        if (data.cp) {
            address += ` (${data.cp})`;
        }
        address += `${(address ? '. ' : '')} <strong>${data.localidad.nombre}</strong>`;
        if (data.barrio) {
            address += `, ${data.barrio.nombre}`
        }
        return address;
    },

}
app.config.globalProperties.$filters = {
    data(filters) {
        const data = Object.keys(filters).map(e => {
            if (e == 'page') return null;
            if (filters[e] == '') return null;
            let aux = {}
            aux[e] = filters[e];
            return aux;
        }).filter(e => e);
        return Object.assign({}, ...data);
    },
    values(data) {
        console.log(data)
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.toString() != '') {
            let filtersUse = {};
            urlParams.forEach((v,k) => {
                if (k != 'pagina') {
                    if (filtersUse[k] !== undefined) {
                        if (!Array.isArray(filtersUse[k])) {
                            filtersUse[k] = [filtersUse[k]]
                        }
                        filtersUse[k].push(v)
                    } else {
                        filtersUse[k] = v
                    }
                }
            });
            data = {...data, ...filtersUse};
        }
        return data;
    },
    goTo(data) {
        router.push(data);
    },
};

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('v-select', vSelect);
app.component('Calendar', Calendar)
app.component('DatePicker', DatePicker)
app.component('VPagination', VPagination)
app.use(GoogleSignInPlugin, {
    clientId: process.env.VUE_APP_CLIENT_ID,
});
app.use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_CLIENT_ID,
});
app.use(CKEditor);
app.mount('#app');
